.pagination {
    list-style: none;
    padding: 0;
    margin-bottom: 0;
    display: flex;
    justify-content: center;
}

.item {
    background: transparent;
    appearance: none;
    background-color: theme('colors.layers.foreground');
    border: theme('card.bordered.border');
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0.6667rem;
    cursor: pointer;
    height: 2.5rem;
    width: 2.5rem;
    line-height: 1;
    margin: 0 theme('spacing.025');
    padding: 0;
    text-align: center;

    @apply flex items-center justify-center;

    > button {

        @apply flex items-center justify-center;
    }

    @media screen and (min-width: 767px) {
        margin: 0 theme('spacing.050');
    }
}

.itemdisabled {
    cursor: not-allowed;
    opacity: 0.25;
}

.itemcurrent {
    box-shadow: 0 35px 45px -20px rgb(0 0 0 / 0.15);
    color: theme('colors.neutrals.white');
    background: theme('colors.content.foundation');
    border: 1px solid theme('colors.content.foundation');
}
