.drawer {
    @apply grid-cols-3;

    display: grid;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: theme('drawerNav.padding');
    background-color: theme('drawerNav.background');
    border-top: 1px solid theme('colors.decorators.subtle');
    z-index: theme('zIndex.500');
    box-shadow: theme('drawerNav.shadow');

    @media screen and (min-width: theme('screens.md')) {
        display: none;
    }
}

.button {
    @apply type-heading type-090;

    display: flex;
    align-items: center;
    justify-content: center;
    gap: theme('drawerNavLink.spacing');
    padding: theme('drawerNavLink.padding');
}
