.header {
    display: flex;
    flex-direction: column;
    gap: theme('spacing.100');
    padding: 0 theme('container.gutters.small');
    margin-top: theme('rhythm.xsmall');

    @media (min-width: theme('screens.md')) {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 0 theme('container.gutters.medium');
    }

    @media (min-width: theme('screens.lg')) {
        margin-top: theme('rhythm.small');
    }
}

.accountVerifiedBadge {
    display: inline-flex;
    align-items: center;
    margin: theme('spacing.025') auto 0 0;
    gap: theme('spacing.050');
}
