.wrapper {
    display: flex;
    gap: theme('spacing.050');
    align-items: center;
    width: 100%;
    background-color: theme('card.bordered.background');
    border-radius: theme('card.borderRadius.default');
    text-align: left;
}

.img {
    flex-shrink: 0;
    width: theme('sizes.400');
    height: theme('sizes.400');
}

.details {
    display: inline-block;
}

.dot {
    display: inline-block;
    border-radius: theme('borderRadius.full');
    width: theme('sizes.025');
    height: theme('sizes.025');
    background-color: theme('colors.decorators.subtle');
    margin-right: theme('spacing.050');
    transform: translateY(-2px);
}

.tag {
    display: inline-block;
    padding: theme('tag.padding.medium');
    /* Cant use them as it has no opacity - background: theme('tag.promotionSale.color'); */
    background: rgba(255, 84, 157, 0.5);
    border-radius: theme('tag.borderRadius');
    /* Wrong color - color: theme('tag.promotionSale.textColor'); */
    color: theme('colors.content.foundation');
}
