.container {

    @apply  grid
        grid-cols-1
        gap-x-150
        gap-y-150;

    @container (min-width: 768px) {

        @apply grid-cols-2;
    }

    @container (min-width: 1152px) {

        @apply grid-cols-3;
    }
}

.skeleton {

    @apply  h-[200px];
}