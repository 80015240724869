.container {
    max-width: theme('container.sizes.large');

    margin-left: auto;
    margin-right: auto;
    padding: 0 theme('container.gutters.small');

    @media (min-width: theme('screens.md')) {
        padding: 0 theme('container.gutters.medium');
    }
}

.spacing {
    margin-top: theme('spacing.rhythm-medium');

    @media (min-width: theme('screens.lg')) {
        margin-top: theme('spacing.rhythm-large');
    }

    &:last-child {
        margin-bottom: theme('spacing.rhythm-medium');

        @media (min-width: theme('screens.lg')) {
            margin-bottom: theme('spacing.rhythm-large');
        }
    }
}

.grid {
    @apply grid-cols-1;

    display: grid;
    gap: theme('spacing.100');
}

.featuredGridOfTwoOrLess {
    @media (min-width: theme('screens.lg')) {
        @apply grid-cols-2;
    }
}

.featuredGridOfThreeOrMore {
    @media (min-width: theme('screens.md')) {
        @apply grid-cols-2;
    }

    @media (min-width: theme('screens.lg')) {
        @apply grid-cols-6;
    }

    li {
        @media (min-width: theme('screens.md')) {
            &:nth-child(1),
            &:nth-child(2) {
                @apply col-span-2;
            }
        }

        @media (min-width: theme('screens.lg')) {
            @apply col-span-2;

            &:first-child {
                @apply row-span-2 col-span-3;
            }

            &:nth-child(2),
            &:nth-child(3) {
                @apply col-span-3;
            }
        }
    }
}
