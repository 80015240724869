.listItem {
    position: relative;

    &:hover,
    &:focus-within,
    &:focus {
        > ul {
            opacity: 1;
            pointer-events: all;
            transform: translateY(0);
        }
    }
}

.listItemMobileHidden {
    display: none;

    @media screen and (min-width: 899px) {
        display: block;
    }
}

.border {
    height: 1px;
    background-color: theme('colors.decorators.subtle');
    margin-top: theme('spacing.025');
    margin-bottom: theme('spacing.025');
}

.pinnedToRight {
    right: 0;
}
