.wrapper {
    padding: theme('spacing.050');
    background: theme('colors.layers.highlight');
    border-radius: theme('borderRadius.200');
    text-align: center;

    @media (min-width: theme('screens.md')) {
        padding: theme('spacing.100');
    }
}

.content {
    @apply space-y-100;

    padding: theme('borderRadius.200') theme('borderRadius.100');
    border: theme('borderWidth.100') solid var(--border-color, theme('colors.decorators.strong'));
    border-radius: theme('borderRadius.200');

    @media (min-width: theme('screens.md')) {
        padding: theme('spacing.300') theme('spacing.200');
    }

    & > * {
        max-width: 38rem;
        margin-left: auto;
        margin-right: auto;
    }
}
