.fluid_table {
    width: 100%;
    border-collapse: collapse;
    background-color: white;
    display: flex;
    flex-direction: column;
    border-radius: theme('card.borderRadius.default');
    border: 1px solid theme('colors.decorators.subtle');

    @media screen and (min-width: 768px) {

        @apply  table-row-group border-none;
    }
}

.fluid_table__header {
    display: none;
    border-top-left-radius: theme('card.borderRadius.default');
    border-top-right-radius: theme('card.borderRadius.default');
    background-color: theme('table.header.background');

    th {
        padding: theme('table.header.padding.large');
        text-align: left;
    }

    @media screen and (min-width: 768px) {
        display: flex;

        + .fluid_table__body {
            display: flex;
            flex-direction: column;
        }
    }
}

.fluid_table__row {

    @apply flex w-full;
}