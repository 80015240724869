.section {
    position: relative;
    background: theme('colors.layers.strong');
    border-radius: theme('borderRadius.200') theme('borderRadius.200') 0 0;
    overflow: hidden;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: theme('colors.layers.strongFilter');
        pointer-events: none;
    }
}

.container {
    @apply grid-cols-1;

    display: grid;
    gap: theme('spacing.300');

    @media screen and (min-width: theme('screens.md')) {
        @apply grid-cols-2 container-gutters-large;

        gap: theme('spacing.200');
    }
}

.image {
    max-width: none;
    width: calc(100% + theme('spacing.150'));
    height: 16.75rem;
    margin-left: auto;
    border-top-left-radius: 10rem;
    object-fit: cover;

    @media screen and (min-width: theme('screens.md')) {
        position: absolute;
        top: 0;
        left: 0;
        width: calc(100% + theme('spacing.200'));
        height: 100%;
        margin: 0;
        object-fit: cover;
        border-top-left-radius: 0;
        border-bottom-left-radius: 1000px;
    }

    @media screen and (min-width: 1168px) {
        width: 45.75rem;
    }
}
