.tag {
    @apply type-emphasis type-080;

    display:inline-block;
    border-radius: theme('tag.borderRadius');
    background: theme('tag.default.color');
    color: theme('tag.default.textColor');
}

/* Sizes */
.medium {
    padding: theme('tag.padding.medium');
    padding-top: 0.1875rem;
    padding-bottom: 0.3125rem;
    line-height: 100%;
}

.large {
    @apply type-080;

    padding: theme('tag.padding.large');
    padding-top: 0.375rem;
    padding-bottom: 0.625rem;
    line-height: 110%;
}

/* Variants */

.bestSeller {
    background: theme('tag.promotionBestSeller.color');
    color: theme('tag.promotionBestSeller.textColor');
}

.bundlePack {
    background: theme('tag.promotionBundlePack.color');
    color: theme('tag.promotionBundlePack.textColor');
}

.error {
    background: theme('tag.error.color');
    color: theme('tag.error.textColor');
}

.warning {
    background: theme('tag.warning.color');
    color: theme('tag.warning.textColor');
}

.success {
    background: theme('tag.success.color');
    color: theme('tag.success.textColor');
}

.info {
    background: theme('tag.info.color');
    color: theme('tag.info.textColor');
}

.specialOffer {
    background: theme('tag.promotionSpecialOffer.color');
    color: theme('tag.promotionSpecialOffer.textColor');
}

.newProduct {
    background: theme('tag.promotionNewProduct.color');
    color: theme('tag.promotionNewProduct.textColor');
}

.multiBuy {
    background: theme('tag.promotionMultiBuy.color');
    color: theme('tag.promotionMultiBuy.textColor');
}

.newPackaging {
    background: theme('tag.promotionNewPackaging.color');
    color: theme('tag.promotionNewPackaging.textColor');
}

.sale {
    background: theme('tag.promotionSale.color');
    color: theme('tag.promotionSale.textColor');
}

.discountCode {
    background: theme('tag.discountCode.color');
    color: theme('tag.discountCode.textColor');
}

.priceSaving {
    background: theme('tag.priceSaving.color');
    color: theme('tag.priceSaving.textColor');
}
