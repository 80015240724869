.lastPrescription {
    > div:first-of-type {
        border-bottom: 1px solid theme('colors.decorators.subtle');
    }
}

.image {
    width: theme('sizes.300');
    height: theme('sizes.300');
}
