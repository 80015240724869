.avatar {
    height: var(--avatar-height, 3rem);
    width: var(--avatar-width, 3rem);
    border-radius: var(--avatar-width, 2rem);
    aspect-ratio: 33.6 / 48;
    object-fit: cover;
}

/* --- Sizes --- */

.small {
    --avatar-height: theme('avatar.halfPillUp.small.height');
    --avatar-width: theme('avatar.halfPillUp.small.width');
    border-radius: theme('avatar.halfPillUp.small.borderRadius');
}

.medium {
    --avatar-height: theme('avatar.halfPillUp.medium.height');
    --avatar-width: theme('avatar.halfPillUp.medium.width');
    border-radius: theme('avatar.halfPillUp.medium.borderRadius');
}

.large {
    --avatar-height: theme('avatar.halfPillUp.large.height');
    --avatar-width: theme('avatar.halfPillUp.large.width');
    border-radius: theme('avatar.halfPillUp.large.borderRadius');
}

.xlarge {
    --avatar-height: theme('avatar.halfPillUp.xlarge.height');
    --avatar-width: theme('avatar.halfPillUp.xlarge.width');
    border-radius: theme('avatar.halfPillUp.xlarge.borderRadius');
}
