.assessment {
    @apply flex
    flex-col
    container-medium
    mx-auto
    gap-rhythm-small
    md:flex-row
    md:gap-200;
}

.sectionTitle {
    @apply md:text-800 md:font-500 lg:text-1000 lg:font-400 lg:leading-700;

    span {
        @apply text-content-accent block font-500;
    }
}

.card {
    padding: theme('spacing.150');

    @media (min-width: theme('screens.md')) {
        max-width: 23rem;
        margin-top: theme('spacing.200');
        margin-left: auto;
    }

    width: 100%;
    max-width: 24.37rem;
}

.cardHeader {
    @apply md:text-600 md:leading-600 lg:text-700 lg:leading-700;
}

.cardList {
    @apply display-none
    flex-col
    list-none
    gap-100
    basis-1/2
    mt-200
    md:flex
    @md:container-small
    @md:justify-center
    @md:gap-100
    @lg:gap-150;
}

.cardList:nth-child(3) {
    @apply flex
    text-layers-highlight
    mt-150
    md:display-none;
}
