.footer {
    padding: theme('spacing.300') theme('container.gutters.small');
    background-color: theme('footer.background');
    border-radius: theme('borderRadius.200') theme('borderRadius.200') 0 0;
    color: theme('footer.textColor');
    fill: theme('footer.textColor');

    @media (min-width: theme('screens.md')) {
        padding: theme('spacing.400') theme('container.gutters.large');
    }

    a {
        &:focus {
            outline: 2px solid theme('colors.layers.strong');
            outline-offset: theme('spacing.025');
        }
    }
}
