.borderColorDefault {
    --border-color: theme('colors.decorators.accent');
}

.borderColorCard {
    --border-color: theme('colors.decorators.subtle');
}

.wrapper {
    @apply @container;

    border-radius: theme('borderRadius.200');
    border: theme('borderWidth.100') solid var(--border-color, theme('colors.decorators.accent'));
    background: theme('card.default.background');
}

.content {
    display: flex;
    flex-direction: column;
    align-items: start;
    margin: theme('spacing.050');
    border-radius: theme('borderRadius.200');
    border: theme('borderWidth.100') solid var(--border-color, theme('colors.decorators.muted'));
    overflow: hidden;

    @container (min-width: 540px) {
        margin: theme('spacing.100');
    }

    @container (min-width: 720px) {
        flex-direction: row;
        gap: theme('spacing.100');
        align-items: stretch;
        justify-content: space-between;
        margin: theme('spacing.100');
    }

    > div:last-child,
    > button:last-child {
        flex-grow: 0;
        flex-shrink: 0;
    }
}

.text {
    @apply space-y-050;

    position: relative;
    padding: theme('spacing.200') theme('spacing.100');

    @container (min-width: 720px) {
        padding: theme('spacing.200') theme('spacing.150');
    }
}

.defaultButtonWrapper {
    position: relative;
    padding: theme('spacing.200') theme('spacing.100');
    border-radius: 0 3.5rem 3.5rem 0;
    max-width: calc(100% - 1rem);

    @container (min-width: 720px) {
        display: flex;
        align-items: center;
        max-width: 18.75rem;
        padding: 0 theme('spacing.150') 0 theme('spacing.200');
    }
}

.buttonWrapper {
    position: relative;
    padding: theme('spacing.200');
    border-radius: 0 3.5rem 3.5rem 0;
    max-width: calc(100% - 1rem);

    @container (min-width: 720px) {
        display: flex;
        align-items: center;
        max-width: 18.75rem;
        padding: 0 theme('spacing.150') 0 theme('spacing.200');
    }

    /* Circle */
    &:before {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        height: 100%;
        width: 50%;
        background: theme('colors.decorators.accent');
        border-radius: 0 100% 100% 0;

        @supports (aspect-ratio: 1 / 1) {
            aspect-ratio: 1 / 1;
            width: auto;
        }

        @container (min-width: 720px) {
            left: 0;
            right: auto;
            border-radius: 100% 0 0 100%;
        }
    }

    /* Square */
    &:after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: calc(100% - 6.875rem);
        height: 100%;
        background: theme('colors.decorators.accent');

        @container (min-width: 720px) {
            left: auto;
            right: 0;
        }
    }

    a {
        position: relative;
        z-index: 1;
    }
}
