.treatmentsGrid {
    @apply
        container-large
        mx-auto
        space-y-100
        lg:space-y-150;
}

.treatments {
    max-width: theme('container.sizes.large');
    margin: theme('spacing.rhythm-medium') auto 0;
    padding: 0 theme('container.gutters.small');

    @media (min-width: theme('screens.md')) {
        margin-top: theme('spacing.rhythm-large');
        padding: 0 theme('container.gutters.medium');
    }
}

.advice {
    @apply mt-300 px-100 container-large mx-auto md:px-150 lg:mt-400;
}

.featuredGuides {
    @apply
        py-300
        lg:py-600;

    width: 100%;
}
