.directors {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    gap: theme('spacing.100');
    justify-content: center;

    img {
        width: 4rem;
        height: 4rem;
        border-radius: 0 0 2rem 2rem;

        @media (min-width: theme('screens.md')) {
            width: 6rem;
            height: 6rem;
            border-radius: 0 0 3rem 3rem;
        }
    }
}
