.basketSummary {
    @apply mt-050;

    flex-shrink: 0;
    border-top: theme('border.light');
    background: theme('colors.neutrals.white');
}

.freeDeliveryNotice {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: theme('spacing.025');
    padding: theme('spacing.050') theme('spacing.100');
    background-color: theme('colors.status.info.light');
    border-radius: theme('borderRadius.100');
}

.saveForLaterButton {
    @apply type-paragraph type-090 -my-050;

    display: block;
    margin: 0 auto;
    vertical-align: top;
    padding: theme('spacing.050');
    text-decoration: underline;
    color: theme('colors.status.info.default');

    &:hover,
    &:focus {
        text-decoration: none;
    }
}
