.wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: theme('spacing.150');
    flex-wrap: wrap;

    @media (min-width: theme('screens.md')) {
        gap: theme('spacing.200');
        flex-wrap: nowrap;
    }

    p {
        display: flex;
        align-items: flex-start;
        gap: theme('spacing.050');

        img {
            display: none;

            @media (min-width: theme('screens.md')) {
                display: block;
            }
        }
    }
}

.images {
    display: flex;
    gap: theme('spacing.150');
    flex-grow: 0;
    flex-shrink: 0;
}
