.footer {
    padding: theme('spacing.300') theme('container.gutters.small');
    background-color: theme('footer.background');
    border-radius: theme('borderRadius.200') theme('borderRadius.200') 0 0;
    color: theme('footer.textColor');
    fill: theme('footer.textColor');

    @media (min-width: theme('screens.md')) {
        padding: theme('spacing.400') theme('container.gutters.large');
    }

    a {
        &:focus {
            outline: 2px solid theme('colors.layers.strong');
            outline-offset: theme('spacing.025');
        }
    }
}

.grid {
    @apply container-large grid-cols-1;

    display: grid;
    margin: 0 auto;
    gap: theme('spacing.300');

    @media (min-width: theme('screens.md')) {
        @apply grid-cols-3;

        row-gap: theme('rhythm.medium');
        column-gap: theme('spacing.200');
    }

    @media (min-width: theme('screens.lg')) {
        grid-template-columns: 18.875rem 1fr 1fr 1fr;

        gap: theme('spacing.300');
    }
}

.details {
    display: flex;
    flex-direction: column;
    gap: theme('spacing.200');

    @media (min-width: theme('screens.md')) {
        flex-direction: row;
        align-items: center;
        gap: theme('spacing.300');
    }

    @media (min-width: theme('screens.md')) and (max-width: theme('screens.lg')) {
        @apply col-span-3;

        justify-content: space-between;
    }

    @media (min-width: theme('screens.lg')) {
        flex-direction: column;
        align-items: start;
        gap: theme('spacing.200');
    }
}
