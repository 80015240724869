.list {
    @apply grid-cols-2;

    display: grid;
    gap: theme('spacing.075');

    @media (min-width: theme('screens.md')) {
        @apply grid-cols-4;
    }

    li {
        text-align: center;

        & > span {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: theme('spacing.050');
            text-wrap: nowrap;
        }
    }
}
