.card {
    position: relative;
    padding: theme('spacing.075');
    border-radius: theme('card.borderRadius.default');
    background-color: theme('fields.default.background');
    cursor: pointer;

    &:focus-visible {
        outline: none;
    }

    &:hover,
    &:focus,
    &:focus-within {
        .indicator {
            border-color: theme('colors.actions.secondary');
        }
    }
}

.indicator {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: inherit;
    border: 1px solid theme('colors.decorators.subtle');
    transition: border-color theme('transitionDuration.fast') theme('transitionTimingFunction.default');
    pointer-events: none;

    &.indicatorActive {
        border: 2px solid theme('colors.actions.secondary');
    }
}
