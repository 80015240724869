.header {
    display: flex;
    align-items: center;
    gap: theme('spacing.050');

    &:after {
        content: '';
        flex-grow: 1;
        flex-shrink: 1;
        height: 1px;
        background-color: theme('colors.decorators.subtle');
    }
}

.tag {
    display: inline-flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    width: 26px;
    height: 26px;
    padding-top: 1px;
    background-color: theme('colors.content.foundation');
    border-radius: 50%;
    text-transform: uppercase;
}

.border {
    flex-grow: 1;
    flex-shrink: 1;
    height: 1px;
    background-color: theme('colors.decorators.subtle');
}

.list {

    @apply
    grid
    gap-050
    grid-cols-1
    @[550px]:grid-cols-2
    @[800px]:grid-cols-3;
}
