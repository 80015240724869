.panel {
    align-self: center;
    max-width: 26.25rem;
    padding: 0 theme('container.gutters.small');
    margin: theme('spacing.rhythm-medium') auto;
    text-align: center;

    @media (min-width: theme('screens.md')) {
        padding: 0 theme('container.gutters.medium');
        margin: theme('spacing.rhythm-large') auto;
    }

    @media (min-width: theme('screens.lg')) {
        max-width: theme('container.sizes.xsmall');
    }
}
