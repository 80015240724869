.cookieBanner {
    @apply z-cookie motion-safe:animate-pop-fade-in;

    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    gap: theme('spacing.100');
    justify-content: center;
    padding: theme('banner.sizes.small') theme('banner.sizes.medium');
    background: theme('banner.general.background');
    border-top-left-radius: theme('borderRadius.100');
    border-top-right-radius: theme('borderRadius.100');

    @media (min-width: theme('screens.lg')) {
        gap: theme('spacing.200')
    }
}
