.spacing {
    margin-top: theme('spacing.rhythm-medium');

    @media (min-width: theme('screens.lg')) {
        margin-top: theme('spacing.rhythm-large');
    }

    &:last-child {
        margin-bottom: theme('spacing.rhythm-medium');

        @media (min-width: theme('screens.lg')) {
            margin-bottom: theme('spacing.rhythm-large');
        }
    }
}

.container {
    padding: 0 theme('container.gutters.small');
    margin-left: auto;
    margin-right: auto;

    @media (min-width: theme('screens.md')) {
        padding: 0 theme('container.gutters.medium');
    }
}

.treatments {
    @apply grid-cols-2;

    display: grid;
    gap: theme('spacing.100');

    @media (min-width: theme('screens.md')) {
        @apply grid-cols-3;
    }

    @media (min-width: theme('screens.lg')) {
        @apply grid-cols-4;
    }
}
