.list {
    margin-top: theme('spacing.150');

    li {
        padding-bottom: theme('spacing.075');
        margin-bottom: theme('spacing.075');
        border-bottom: 1px dashed theme('colors.decorators.subtle');

        &:last-child {
            padding-bottom: 0;
            margin-bottom: 0;
            border-bottom: none;
        }
    }
}

.image {
    flex-shrink: 0;
    width: theme('icon.sizes.large');
    height: theme('icon.sizes.large');
    object-fit: contain;
}
