
.content {
    @apply container-large container-gutters-small md:container-gutters-medium mx-auto;
}

.calloutSection {
    @apply
    container-large
    container-gutters-small
    md:container-gutters-medium
    mx-auto
    my-rhythm-medium
    md:my-rhythm-large;
}