.ConditionPage_treatmentsGrid__PzI7Q {
    max-width: 1152px;
    margin-left: auto;
    margin-right: auto;
}

.ConditionPage_treatmentsGrid__PzI7Q > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(16px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(16px * var(--tw-space-y-reverse));
}

@media (min-width: 1024px) {

    .ConditionPage_treatmentsGrid__PzI7Q > :not([hidden]) ~ :not([hidden]) {
        --tw-space-y-reverse: 0;
        margin-top: calc(24px * calc(1 - var(--tw-space-y-reverse)));
        margin-bottom: calc(24px * var(--tw-space-y-reverse));
    }
}

.ConditionPage_treatments__VoBFX {
    max-width: 1152px;
    margin: 48px auto 0;
    padding: 0 16px;
}

@media (min-width: 768px) {

.ConditionPage_treatments__VoBFX {
        margin-top: 64px;
        padding: 0 24px
}
    }

.ConditionPage_advice__IEs82 {
    max-width: 1152px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 48px;
    padding-left: 16px;
    padding-right: 16px;
}

@media (min-width: 768px) {

    .ConditionPage_advice__IEs82 {
        padding-left: 24px;
        padding-right: 24px;
    }
}

@media (min-width: 1024px) {

    .ConditionPage_advice__IEs82 {
        margin-top: 64px;
    }
}

.ConditionPage_featuredGuides__hy03s {
    padding-top: 48px;
    padding-bottom: 48px;
}

@media (min-width: 1024px) {

    .ConditionPage_featuredGuides__hy03s {
        padding-top: 96px;
        padding-bottom: 96px;
    }
}

.ConditionPage_featuredGuides__hy03s {

    width: 100%;
}

.MiniPanelWrapper_panel__4KJ2I {
    align-self: center;
    max-width: 26.25rem;
    padding: 0 16px;
    margin: 48px auto;
    text-align: center;
}

    @media (min-width: 768px) {.MiniPanelWrapper_panel__4KJ2I {
        padding: 0 24px;
        margin: 64px auto
}
    }

    @media (min-width: 1024px) {.MiniPanelWrapper_panel__4KJ2I {
        max-width: 480px
}
    }

.AccountLink_link__Gnnvj {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 12px 16px;
    border-radius: 6px;
    background-color: rgb(00 00 00 / 0);
    transition: 600ms background-color cubic-bezier(0.23, 1, 0.32, 1);
}

    @media screen and (min-width: 899px) {
        .AccountLink_link__Gnnvj:hover,
        .AccountLink_link__Gnnvj:focus-within {
            background-color: #303c6940;
        }

        .AccountLink_link__Gnnvj:hover {
            outline: none;
        }

        .AccountLink_link__Gnnvj:focus-within {
            outline: 2px solid #007ACC;
        }
    }

.AccountDropdown_listItem__hBDLC {
    position: relative;
}

    .AccountDropdown_listItem__hBDLC:hover > ul, .AccountDropdown_listItem__hBDLC:focus-within > ul, .AccountDropdown_listItem__hBDLC:focus > ul {
            opacity: 1;
            pointer-events: all;
            transform: translateY(0);
        }

.AccountDropdown_listItemMobileHidden__Yi_w7 {
    display: none;
}

@media screen and (min-width: 899px) {

.AccountDropdown_listItemMobileHidden__Yi_w7 {
        display: block
}
    }

.AccountDropdown_border___qY46 {
    height: 1px;
    background-color: #CAD8E2;
    margin-top: 4px;
    margin-bottom: 4px;
}

.AccountDropdown_pinnedToRight__UrK_n {
    right: 0;
}

.TreatmentDescription_descriptionList__H5YLD > *:not(:last-child) {
            border-bottom: 1px solid #CAD8E2;
            padding-bottom: 16px;
            margin-bottom: 16px;
        }

/* Generic page container styling. */
.page_container__a_YkG {
    margin-left: auto;
    margin-right: auto;
    padding: 0 16px;
}
@media (min-width: 768px) {
.page_container__a_YkG {
        padding: 0 24px
}
    }

/* Generic vertical spacing for sections. */
.page_section__YmhI_ {
    margin-top: 48px;
}
@media (min-width: 1024px) {
.page_section__YmhI_ {
        margin-top: 64px
}
    }
.page_section__YmhI_:last-child {
        margin-bottom: 48px;
    }
@media (min-width: 1024px) {
.page_section__YmhI_:last-child {
            margin-bottom: 64px
    }
        }

.page_affiliateBanner__BvtTK + div {
        margin-top: 32px;
    }

.page_description__4_k39 {
    grid-template-columns: repeat(1, minmax(0, 1fr));

    display: grid;
    gap: 48px;
    max-width: 1152px;
}

@media (min-width: 1024px) {

.page_description__4_k39 {
        gap: 64px
}

        .page_description__4_k39:has(> div:not(:only-of-type)) {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }
    }

.page_summary__8Me3f {
    max-width: 1152px;
    margin-top: 16px;
}

@media (min-width: 768px) {

.page_summary__8Me3f {
        margin-top: 48px
}
    }

@media (min-width: 1024px) {

.page_summary__8Me3f {
        margin-top: 64px
}
    }

.Tag_tag__Ru44L {

    font-family:sofia-pro, arial, sans-serif;

    font-weight:700;

    font-size:12.642px;

    line-height:150%;

    display:inline-block;
    border-radius: 1000px;
    background: #007ACC;
    color: #FFFFFF;
}

/* Sizes */
.Tag_medium__sCkJu {
    padding: 4px 8px;
    padding-top: 0.1875rem;
    padding-bottom: 0.3125rem;
    line-height: 100%;
}

.Tag_large__CL75z {

    font-size:12.642px;

    padding: 8px 16px;
    padding-top: 0.375rem;
    padding-bottom: 0.625rem;
    line-height: 110%;
}

/* Variants */

.Tag_bestSeller__5KSDt {
    background: #72EDC7;
    color: #FFFFFF;
}

.Tag_bundlePack__ziO3X {
    background: #FFDF8A;
    color: #FFFFFF;
}

.Tag_error__FNP3G {
    background: #ffebee;
    color: #951b46;
}

.Tag_warning__kNBCH {
    background: #fff2d0;
    color: #776944;
}

.Tag_success__1O_Qj {
    background: #bff7e3;
    color: #468772;
}

.Tag_info__1_lBo {
    background: #cfdcf3;
    color: #1a548a;
}

.Tag_specialOffer__VeM0G {
    background: #DB0F64;
    color: #FFFFFF;
}

.Tag_newProduct__sTAm3 {
    background: #303C69;
    color: #FFFFFF;
}

.Tag_multiBuy__4cb7g {
    background: #007ACC;
    color: #FFFFFF;
}

.Tag_newPackaging__CwIEx {
    background: #56E7FF;
    color: #303C69;
}

.Tag_sale__YmMY9 {
    background: #FF549D;
    color: #FFFFFF;
}

.Tag_discountCode__G_FuK {
    background: #FF549D;
    color: #FFFFFF;
}

.Tag_priceSaving__I6RPe {
    background: #FF549D;
    color: #FFFFFF;
}

.AccountDeliveryAddressEmpty_container__SCa80 {

    
display: flex;

    
flex-direction: column;

    
row-gap: 16px
}

    
    @container (min-width: 768px) {.AccountDeliveryAddressEmpty_container__SCa80 {

    
flex-direction: row;

    
align-items: center;

    
justify-content: space-between
}
    }
.AccountDeliveryAddressList_container__0GzAm {

display: grid;

grid-template-columns: repeat(1, minmax(0, 1fr));

-moz-column-gap: 24px;

     column-gap: 24px;

row-gap: 24px
}

    @container (min-width: 768px) {.AccountDeliveryAddressList_container__0GzAm {

grid-template-columns: repeat(2, minmax(0, 1fr))
}
    }

    @container (min-width: 1152px) {.AccountDeliveryAddressList_container__0GzAm {

grid-template-columns: repeat(3, minmax(0, 1fr))
}
    }

.AccountDeliveryAddressList_skeleton__yERuI {

height: 200px
}
.OrderSummary_list__u150V {
    margin-top: 24px;
}

    .OrderSummary_list__u150V li {
        padding-bottom: 12px;
        margin-bottom: 12px;
        border-bottom: 1px dashed #CAD8E2;
    }

    .OrderSummary_list__u150V li:last-child {
            padding-bottom: 0;
            margin-bottom: 0;
            border-bottom: none;
        }

.OrderSummary_image__BarA5 {
    flex-shrink: 0;
    width: 32px;
    height: 32px;
    -o-object-fit: contain;
       object-fit: contain;
}

.SearchTermEmpty_description__5I_zp {
    line-height: 200%;
}

.DoseCard_dot__m2ofI {
    display: inline-block;
    border-radius: 100%;
    width: 4px;
    height: 4px;
    background-color: #CAD8E2;
}

.LastPrescribedCard_lastPrescription__yh6p2 > div:first-of-type {
        border-bottom: 1px solid #CAD8E2;
    }

.LastPrescribedCard_image__K_RTH {
    width: 48px;
    height: 48px;
}

.FirstOrderCard_wrapper__1uPsM {
    display: flex;
    gap: 8px;
    align-items: center;
    width: 100%;
    background-color: #FFFFFF;
    border-radius: 12px;
    text-align: left;
}

.FirstOrderCard_img__rYfWj {
    flex-shrink: 0;
    width: 64px;
    height: 64px;
}

.FirstOrderCard_details__NMF1M {
    display: inline-block;
}

.FirstOrderCard_dot__r3nBL {
    display: inline-block;
    border-radius: 100%;
    width: 4px;
    height: 4px;
    background-color: #CAD8E2;
    margin-right: 8px;
    transform: translateY(-2px);
}

.FirstOrderCard_tag__oxMMy {
    display: inline-block;
    padding: 4px 8px;
    /* Cant use them as it has no opacity - background: theme('tag.promotionSale.color'); */
    background: rgba(255, 84, 157, 0.5);
    border-radius: 1000px;
    /* Wrong color - color: theme('tag.promotionSale.textColor'); */
    color: #303C69;
}

.BasketSummary_basketSummary__02c3m {

    margin-top: 8px;

    flex-shrink: 0;
    border-top: 1px solid #CAD8E2;
    background: #FFFFFF;
}

.BasketSummary_freeDeliveryNotice__nWQNU {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
    padding: 8px 16px;
    background-color: #9dbae6;
    border-radius: 12px;
}

.BasketSummary_saveForLaterButton__i75K3 {

    font-family: sofia-pro, arial, sans-serif;

    font-weight: 400;

    font-size: 14.222px;

    line-height: 150%;

    margin-top: -8px;

    margin-bottom: -8px;

    display: block;
    margin: 0 auto;
    vertical-align: top;
    padding: 8px;
    text-decoration: underline;
    color: #007ACC;
}

.BasketSummary_saveForLaterButton__i75K3:hover,
    .BasketSummary_saveForLaterButton__i75K3:focus {
        text-decoration: none;
    }

.ExitCallout_wrapper__A1lav {
    padding: 8px;
    background: #CCF2FF;
    border-radius: 24px;
    text-align: center;
}

    @media (min-width: 768px) {.ExitCallout_wrapper__A1lav {
        padding: 16px
}
    }

.ExitCallout_content__ymFdm > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(16px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(16px * var(--tw-space-y-reverse));
}

.ExitCallout_content__ymFdm {

    padding: 24px 12px;
    border: 1px solid var(--border-color, #007acc80);
    border-radius: 24px;
}

@media (min-width: 768px) {

.ExitCallout_content__ymFdm {
        padding: 48px 32px
}
    }

.ExitCallout_content__ymFdm > * {
        max-width: 38rem;
        margin-left: auto;
        margin-right: auto;
    }

.Statistics_list__GV_N5 {

    grid-template-columns: repeat(2, minmax(0, 1fr));

    display: grid;
    gap: 12px;
}

    @media (min-width: 768px) {.Statistics_list__GV_N5 {

        grid-template-columns: repeat(4, minmax(0, 1fr));
    }
    }

    .Statistics_list__GV_N5 li {
        text-align: center;
    }

    .Statistics_list__GV_N5 li > span {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 8px;
            text-wrap: nowrap;
        }

.TreatmentBookmarkContent_tableMinimal__1kJmR {
    margin-top: 16px;
    border: 1px solid #CAD8E2;
    border-radius: 12px;
    text-align: left;
    overflow-x: auto;
}

    .TreatmentBookmarkContent_tableMinimal__1kJmR table {
        min-width: 100%;
        width: auto;
        border-collapse: collapse;
        border-spacing: 0;
    }

    .TreatmentBookmarkContent_tableMinimal__1kJmR table tr + tr {
            border-top: 1px solid #CAD8E2;
        }

    .TreatmentBookmarkContent_tableMinimal__1kJmR table td {
            width: 50%;
            padding: 16px;
        }

    .TreatmentBookmarkContent_tableMinimal__1kJmR table td + td {
                border-left: 1px solid #CAD8E2;
            }

.Regulation_wrapper__DcAgP {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 24px;
    flex-wrap: wrap;
}

    @media (min-width: 768px) {.Regulation_wrapper__DcAgP {
        gap: 32px;
        flex-wrap: nowrap
}
    }

    .Regulation_wrapper__DcAgP p {
        display: flex;
        align-items: flex-start;
        gap: 8px;
    }

    .Regulation_wrapper__DcAgP p img {
            display: none;
        }

    @media (min-width: 768px) {

    .Regulation_wrapper__DcAgP p img {
                display: block
        }
            }

.Regulation_images__2lhzn {
    display: flex;
    gap: 24px;
    flex-grow: 0;
    flex-shrink: 0;
}

.MeetTheTeam_directors__zXCQJ {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    gap: 16px;
    justify-content: center;
}

    .MeetTheTeam_directors__zXCQJ img {
        width: 4rem;
        height: 4rem;
        border-radius: 0 0 2rem 2rem;
    }

    @media (min-width: 768px) {

    .MeetTheTeam_directors__zXCQJ img {
            width: 6rem;
            height: 6rem;
            border-radius: 0 0 3rem 3rem
    }
        }

.Ad_serviceStamp__mFuqR {

    max-width: 960px;

    padding-left: 16px;

    padding-right: 16px;

    margin-left: auto;

    margin-right: auto;

    margin-top: 48px
}

@media (min-width: 768px) {

    .Ad_serviceStamp__mFuqR {

        padding-left: 24px;

        padding-right: 24px
    }
}

@media (min-width: 1024px) {

    .Ad_serviceStamp__mFuqR {

        margin-top: 64px
    }
}

.Ad_trustBlock__erGG7 {

    max-width: 1152px;

    padding-left: 16px;

    padding-right: 16px;

    margin-left: auto;

    margin-right: auto;

    margin-top: 48px
}

@media (min-width: 768px) {

    .Ad_trustBlock__erGG7 {

        padding-left: 24px;

        padding-right: 24px
    }
}

@media (min-width: 1024px) {

    .Ad_trustBlock__erGG7 {

        margin-top: 64px
    }
}

.Ad_container__1Fow8 {

    max-width: 960px;

    padding-left: 16px;

    padding-right: 16px;

    margin-left: auto;

    margin-right: auto;

    margin-top: 48px;

    margin-bottom: 48px
}

@media (min-width: 768px) {

    .Ad_container__1Fow8 {

        padding-left: 24px;

        padding-right: 24px
    }
}

@media (min-width: 1024px) {

    .Ad_container__1Fow8 {

        margin-top: 64px;

        margin-bottom: 64px
    }
}

.Ad_ordering__A3ywc {

    max-width: 1440px;

    padding-left: 16px;

    padding-right: 16px;

    margin-left: auto;

    margin-right: auto;

    margin-top: 48px
}

@media (min-width: 768px) {

    .Ad_ordering__A3ywc {

        padding-left: 24px;

        padding-right: 24px
    }
}

@media (min-width: 1024px) {

    .Ad_ordering__A3ywc {

        margin-top: 64px
    }
}
.OfferFlash_flash__yYs_2 {

    display: flex;

    align-items: center;

    justify-content: space-between;

    gap: 8px;

    padding: 16px;
    border-radius: 12px;
    border: 1px dashed #CCF2FF;
}

.OfferFlash_flashIcon__4uyfN {
    fill: #FFFFFF;
}

.FilterSection_header___5RF2 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 16px;
}

.AuthorMeta_authorMeta__zIwPs > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(32px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(32px * var(--tw-space-y-reverse));
}

.AuthorMeta_authorMeta__zIwPs {
    --tw-bg-opacity: 1;
    background-color: rgb(248 248 248 / var(--tw-bg-opacity));
    padding-top: 32px;
    padding-bottom: 32px;
}

@media (min-width: 768px) {

    .AuthorMeta_authorMeta__zIwPs {
        padding-top: 48px;
        padding-bottom: 48px;
    }
}

.AuthorMeta_container__vJxRM {
    max-width: 1152px;
    padding: 0 16px;
    margin-left: auto;
    margin-right: auto;
}

@media (min-width: 768px) {

.AuthorMeta_container__vJxRM {
        padding: 0 24px
}
    }

@media (min-width: 1210px) {

.AuthorMeta_container__vJxRM {
        padding: 0
}
    }

.AuthorMeta_metadata__WWHMN {
    display: flex;
    justify-content: space-between;
    padding-bottom: 16px;

    border-bottom: 1px solid #CAD8E2;
}

.AuthorMeta_metadata__WWHMN:last-of-type {
        border-bottom: none;
    }

.AuthorMeta_authorInfo__BcZAo > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(12px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(12px * var(--tw-space-y-reverse));
}

.AuthorMeta_authorInfo__BcZAo {
    padding-bottom: 32px;
}

@media (min-width: 768px) {

    .AuthorMeta_authorInfo__BcZAo {
        padding-bottom: 0;
    }
}

.AuthorMeta_linksContainer__hUegE {
    grid-column: span 12 / span 12;
    padding-bottom: 16px;
}

@media (min-width: 768px) {

    .AuthorMeta_linksContainer__hUegE {
        grid-column: span 4 / span 4;
    }
}

.AuthorMeta_linksContainer__hUegE {

    border-bottom: 1px solid #CAD8E2;
}

@media (min-width: 768px) {

.AuthorMeta_linksContainer__hUegE {
        padding-bottom: 0;

        border-bottom: none;
    }
    }

.AuthorMeta_linksContainer__hUegE:last-of-type {
        border-bottom: none;
    }

.Avatar_avatar__mw5zD {
    height: var(--avatar-height, 3rem);
    width: var(--avatar-width, 3rem);
    border-radius: var(--avatar-width, 2rem);
    aspect-ratio: 33.6 / 48;
    -o-object-fit: cover;
       object-fit: cover;
}

/* --- Sizes --- */

.Avatar_small__x6Sj2 {
    --avatar-height: 32px;
    --avatar-width: 32px;
    border-radius: 2px 2px 24px 24px;
}

.Avatar_medium__IRHfN {
    --avatar-height: 48px;
    --avatar-width: 48px;
    border-radius: 2px 2px 24px 24px;
}

.Avatar_large__yzSn2 {
    --avatar-height: 64px;
    --avatar-width: 64px;
    border-radius: 2px 2px 32px 32px;
}

.Avatar_xlarge__Ayj8g {
    --avatar-height: 96px;
    --avatar-width: 96px;
    border-radius: 2px 2px 48px 48px;
}

.AboutUsCallout_calloutSection__aFIA_ {

    padding-left: 16px;

    padding-right: 16px;

    overflow: hidden;

    padding: 8px;
}@media (min-width: 768px) {.AboutUsCallout_calloutSection__aFIA_ {

        padding-left: 24px;

        padding-right: 24px;

        padding: 16px;
    }
}.AboutUsCallout_calloutSection__aFIA_ {

    border: 1px solid #CAD8E2;
    border-radius: 24px;
    border-color: #CCF2FF;
}

.AboutUsCallout_calloutContent__mVYii {
    border: 1px solid #CAD8E2;
    padding: 32px;
    border-radius: 24px;
    border-color: #CCF2FF;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    max-width: 72rem;
    flex-direction: column;
    align-items: center;
    gap: 32px;
    padding-top: 48px;
    padding-bottom: 48px;
}

@media (min-width: 1024px) {.AboutUsCallout_calloutContent__mVYii {

        flex-direction: row;

        justify-content: space-between;

        gap: 16px;
    }
}

@media (min-width: 619px) {

.AboutUsCallout_calloutContent__mVYii {

        padding-top: 64px;

        padding-bottom: 64px;
    }
    }

@media (min-width: 1024px) {

.AboutUsCallout_calloutContent__mVYii > :not([hidden]) ~ :not([hidden]) {

        --tw-space-y-reverse: 0;

        margin-top: calc(0 * calc(1 - var(--tw-space-y-reverse)));

        margin-bottom: calc(0 * var(--tw-space-y-reverse));
    }
    }

.MeetTheManagement_directors__jyuoY {
    margin-left: auto;
    margin-right: auto;
    max-width: 36.25rem;
}@media (min-width: 1024px) {.MeetTheManagement_directors__jyuoY {
        max-width: 720px;
    }
}.MeetTheManagement_directors__jyuoY {
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    gap: 16px;
}


.MeetTheManagement_quote__v9tGL div {
    justify-content: flex-start;
}
.MeetTheStaff_item__nGnlA {
    padding-bottom: 32px;
    margin-bottom: 32px;
    border-bottom: 1px solid #CAD8E2;
}

.DirectoryGroup_header__nL_rr {
    display: flex;
    align-items: center;
    gap: 8px;
}

    .DirectoryGroup_header__nL_rr:after {
        content: '';
        flex-grow: 1;
        flex-shrink: 1;
        height: 1px;
        background-color: #CAD8E2;
    }

.DirectoryGroup_tag__hQ8cq {
    display: inline-flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    width: 26px;
    height: 26px;
    padding-top: 1px;
    background-color: #303C69;
    border-radius: 50%;
    text-transform: uppercase;
}

.DirectoryGroup_border__OqZ_e {
    flex-grow: 1;
    flex-shrink: 1;
    height: 1px;
    background-color: #CAD8E2;
}

.DirectoryGroup_list__DRein {
    display: grid;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    gap: 8px;
}

@container (min-width: 550px) {

    .DirectoryGroup_list__DRein {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }
}

@container (min-width: 800px) {

    .DirectoryGroup_list__DRein {
        grid-template-columns: repeat(3, minmax(0, 1fr));
    }
}

.SearchCallout_section__euM91 {
    position: relative;
    background: #007ACC;
    border-radius: 24px 24px 0 0;
    overflow: hidden;
}

    .SearchCallout_section__euM91::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(0deg, rgba(48, 60, 105, 0.00) 42.25%, rgba(48, 60, 105, 0.50) 100%);
        pointer-events: none;
    }

.SearchCallout_container___fFkS {
    grid-template-columns: repeat(1, minmax(0, 1fr));

    display: grid;
    gap: 48px;
}

@media screen and (min-width: 768px) {

.SearchCallout_container___fFkS {
        padding-left: 32px;
        padding-right: 32px;
        grid-template-columns: repeat(2, minmax(0, 1fr));

        gap: 32px;
    }
    }

.SearchCallout_image__Vzlvf {
    max-width: none;
    width: calc(100% + 24px);
    height: 16.75rem;
    margin-left: auto;
    border-top-left-radius: 10rem;
    -o-object-fit: cover;
       object-fit: cover;
}

@media screen and (min-width: 768px) {

.SearchCallout_image__Vzlvf {
        position: absolute;
        top: 0;
        left: 0;
        width: calc(100% + 32px);
        height: 100%;
        margin: 0;
        -o-object-fit: cover;
           object-fit: cover;
        border-top-left-radius: 0;
        border-bottom-left-radius: 1000px
}
    }

@media screen and (min-width: 1168px) {

.SearchCallout_image__Vzlvf {
        width: 45.75rem
}
    }

.page_spacing__Rg100 {
    margin-top: 48px;
}

    @media (min-width: 1024px) {.page_spacing__Rg100 {
        margin-top: 64px
}
    }

    .page_spacing__Rg100:last-child {
        margin-bottom: 48px;
    }

    @media (min-width: 1024px) {

    .page_spacing__Rg100:last-child {
            margin-bottom: 64px
    }
        }

.page_container__SgtmB {
    padding: 0 16px;
    margin-left: auto;
    margin-right: auto;
}

@media (min-width: 768px) {

.page_container__SgtmB {
        padding: 0 24px
}
    }

.page_treatments__PuVCw {
    grid-template-columns: repeat(2, minmax(0, 1fr));

    display: grid;
    gap: 16px;
}

@media (min-width: 768px) {

.page_treatments__PuVCw {
        grid-template-columns: repeat(3, minmax(0, 1fr));
    }
    }

@media (min-width: 1024px) {

.page_treatments__PuVCw {
        grid-template-columns: repeat(4, minmax(0, 1fr));
    }
    }

.BookmarkNavigation_bookmark___OvFu {
    border: 2px solid transparent;
    background: #FFFFFF;
    border-radius: 12px;
    box-shadow: 0px 19px 25px 0px #0000000F, 0px 2px 4px 0px #0000000A;
    padding: 8px;
}

.BookmarkNavigation_bookmarkNavItem__e4UrJ {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
    font-size: 14.222px;
    padding: 12px;
}

.BookmarkNavigation_bookmarkNavItem__e4UrJ:hover {
        border-radius: 6px;
        background: #F8F8F8;
        color: #303C69;
        fill: #303C69;

    }
.ConsultationAssessmentCard_assessment__dT_S_ {
    max-width: 960px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column;
    gap: 32px;
}

@media (min-width: 768px) {

    .ConsultationAssessmentCard_assessment__dT_S_ {
        flex-direction: row;
        gap: 32px;
    }

    .ConsultationAssessmentCard_sectionTitle__BMler {
        font-size: 36.491px;
        font-weight: 500;
    }
}

@media (min-width: 1024px) {

    .ConsultationAssessmentCard_sectionTitle__BMler {
        font-size: 46.184px;
        font-weight: 400;
        line-height: 120%;
    }
}

.ConsultationAssessmentCard_sectionTitle__BMler span {
    display: block;
    font-weight: 500;
    --tw-text-opacity: 1;
    color: rgb(49 204 156 / var(--tw-text-opacity));
}

.ConsultationAssessmentCard_card__gJe0X {
    padding: 24px;
}

@media (min-width: 768px) {

.ConsultationAssessmentCard_card__gJe0X {
        max-width: 23rem;
        margin-top: 32px;
        margin-left: auto;
}
    }

.ConsultationAssessmentCard_card__gJe0X {

    width: 100%;
    max-width: 24.37rem;
}

@media (min-width: 768px) {

    .ConsultationAssessmentCard_cardHeader__tws0l {
        font-size: 28.833px;
        line-height: 124%;
    }
}

@media (min-width: 1024px) {

    .ConsultationAssessmentCard_cardHeader__tws0l {
        font-size: 32.437px;
        line-height: 120%;
    }
}

.ConsultationAssessmentCard_cardList__mtd2l {
    margin-top: 32px;
    flex-basis: 50%;
    list-style-type: none;
    flex-direction: column;
    gap: 16px;
    display: none;
}

@container (min-width: 28rem) {

    .ConsultationAssessmentCard_cardList__mtd2l {
        max-width: 720px;
        justify-content: center;
        gap: 16px;
    }
}

@container (min-width: 32rem) {

    .ConsultationAssessmentCard_cardList__mtd2l {
        gap: 24px;
    }
}

@media (min-width: 768px) {

    .ConsultationAssessmentCard_cardList__mtd2l {
        display: flex;
    }
}

.ConsultationAssessmentCard_cardList__mtd2l:nth-child(3) {
    margin-top: 24px;
    display: flex;
    --tw-text-opacity: 1;
    color: rgb(204 242 255 / var(--tw-text-opacity));
}

@media (min-width: 768px) {

    .ConsultationAssessmentCard_cardList__mtd2l:nth-child(3) {
        display: none;
    }
}

.FeaturedGuides_container__lD14C {

max-width: 1152px;

padding-left: 16px;

padding-right: 16px;

margin-left: auto;

margin-right: auto
}

.FeaturedGuides_container__lD14C > :not([hidden]) ~ :not([hidden]) {

--tw-space-y-reverse: 0;

margin-top: calc(24px * calc(1 - var(--tw-space-y-reverse)));

margin-bottom: calc(24px * var(--tw-space-y-reverse))
}

@media (min-width: 768px) {

.FeaturedGuides_container__lD14C {

padding-left: 24px;

padding-right: 24px
}
}

.FeaturedGuides_upperButton__4zAaA {

display: none
}

@container (min-width: 1152px) {

.FeaturedGuides_upperButton__4zAaA {

display: block
}
    }

.Breadcrumbs_container__vfgEc {
    position: absolute;
    left: 0;
    right: 0;
    display: flex;
    max-width: 1152px;
    margin-left: auto;
    margin-right: auto;
}

.Breadcrumbs_containerPadding___B9b6 {
    padding: 0 16px;
}

@media (min-width: 768px) {

.Breadcrumbs_containerPadding___B9b6 {
        padding: 4px 24px
}
    }

.Breadcrumbs_list__yKeNX {
    display: flex;
    align-items: center;
    gap: 8px;
    overflow-x: auto;
}

.Breadcrumbs_listItem__jq0Zq {
    display: flex;
    align-items: center;
    gap: 8px;
    flex-shrink: 0;
}

.Hero_Hero__Ugk3x {

    position: relative;

    z-index: 1;
}.Hero_Hero__Ugk3x:before {

    content: '';

    position: absolute;

    left: 0;

    right: 0;

    height: 40px;

    background: var(--before-bg-color, var(--after-bg-color, transparent));

    z-index: 1;

    top: -40px;
}.Hero_Hero__Ugk3x {

    padding-top: 48px;
    padding-bottom: 48px;
    border-radius: 0 0 24px 24px;
    background-color: #303C69;
    --before-bg-color: #303C69;
    color: #FFFFFF;
}

    @media (min-width: 1024px) {.Hero_Hero__Ugk3x {
        padding-top: 64px;
        padding-bottom: 64px
}
        .Hero_Hero__Ugk3x:before {
            content: none;
        }
    }

.Hero_HeroInner__otvxu {

    padding-left: 16px;

    padding-right: 16px;
}

@media (min-width: 768px) {@media not all and (min-width: 1024px) {.Hero_HeroInner__otvxu {

            padding-left: 24px;

            padding-right: 24px;
        }
    }
}

.Hero_HeroInner__otvxu {

    display: flex;
    flex-direction: row-reverse;
    gap: 32px;
}

.Hero_HeroImageWrapper__22XPH {
    display: none;
}

@media (min-width: 768px) {

.Hero_HeroImageWrapper__22XPH {
        position: relative;
        flex-basis: 21.375rem;
        flex-shrink: 0;
        display: block
}
    }

@media (min-width: 1024px) {

.Hero_HeroImageWrapper__22XPH {

        margin-right: -16px;

        flex-basis: 29rem;
    }
    }

.Hero_HeroSearchWrapper__EdJNm {
    margin-top: 32px;
}

@media (min-width: 768px) {

.Hero_HeroSearchWrapper__EdJNm {
        position: relative;
        width: calc(100% + 6rem)
}
    }

@media (min-width: 1024px) {

.Hero_HeroSearchWrapper__EdJNm {
        width: calc(100% + 5rem)
}
    }

.Hero_image__5_3pO {
    position: absolute;
    top: 0;
    right: 0;
    border-radius: 24px 24px 100% 100%;
}

.AccountHeader_header__b5JLm {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 0 16px;
    margin-top: 24px;
}

    @media (min-width: 768px) {.AccountHeader_header__b5JLm {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 0 24px
}
    }

    @media (min-width: 1024px) {.AccountHeader_header__b5JLm {
        margin-top: 32px
}
    }

.AccountHeader_accountVerifiedBadge__dGrfW {
    display: inline-flex;
    align-items: center;
    margin: 4px auto 0 0;
    gap: 8px;
}

.AccountDeliveryAddresses_container__4HwSu {
    padding-left: 16px;
    padding-right: 16px;
    width: 100%
}
.AccountDeliveryAddresses_container__4HwSu > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(24px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(24px * var(--tw-space-y-reverse))
}
.AccountDeliveryAddresses_container__4HwSu {
    padding-top: 24px;
    padding-bottom: 24px
}
@media (min-width: 768px) {
    .AccountDeliveryAddresses_container__4HwSu {
        padding-left: 24px;
        padding-right: 24px
    }
}
@media (min-width: 1024px) {
    .AccountDeliveryAddresses_container__4HwSu {
        padding-top: 32px;
        padding-bottom: 32px
    }
}

.Tabs_tabs__iDReC {

    font-family: fields, arial, system-ui, serif;

    font-weight: 600;

    font-size: 16px;

    line-height: 150%;

    gap: 16px;
    color: #5A5E6E;
}

.Tabs_tab__87Wgp {
    position: relative;
    display: block;
    padding: 12px 24px;
    margin-bottom: -1px;
    border: 1px solid transparent;
    border-radius: 12px 12px 0 0;
    background: transparent;
}

.Tabs_tabActive__eMGtY {
    border: 1px solid #CAD8E2;
    border-bottom-color: transparent;
    background: #FFFFFF;
    color: #303C69;
}

.AccountShortcutTile_shortcutTile__HMAt5 {
    border: 1px solid transparent;
    border-radius: 12px;
    background: #FFFFFF;
    border: 1px solid #CAD8E2;
    box-shadow: 0px 19px 25px 0px #0000000F, 0px 2px 4px 0px #0000000A;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 16px;
    --tw-shadow: 0 0 #0000;
    --tw-shadow-colored: 0 0 #0000;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}
.SelectorCard_card__ggqAM {
    position: relative;
    padding: 12px;
    border-radius: 12px;
    background-color: #FFFFFF;
    cursor: pointer;
}

    .SelectorCard_card__ggqAM:focus-visible {
        outline: none;
    }

    .SelectorCard_card__ggqAM:hover .SelectorCard_indicator__osZ3S, .SelectorCard_card__ggqAM:focus .SelectorCard_indicator__osZ3S, .SelectorCard_card__ggqAM:focus-within .SelectorCard_indicator__osZ3S {
            border-color: #007ACC;
        }

.SelectorCard_indicator__osZ3S {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: inherit;
    border: 1px solid #CAD8E2;
    transition: border-color 300ms cubic-bezier(0.23, 1, 0.32, 1);
    pointer-events: none;
}

.SelectorCard_indicator__osZ3S.SelectorCard_indicatorActive__K6vHu {
        border: 2px solid #007ACC;
    }

.CookiesNotification_cookieBanner__WV7AL {

    z-index: 1200;
}@media (prefers-reduced-motion: no-preference) {@keyframes CookiesNotification_pop-fade-in__vyFce {}.CookiesNotification_cookieBanner__WV7AL {

        animation: CookiesNotification_pop-fade-in__vyFce 0.2s ease-in-out forwards;
    }
}.CookiesNotification_cookieBanner__WV7AL {

    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    gap: 16px;
    justify-content: center;
    padding: 8px 16px;
    background: #CCF2FF;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
}

    @media (min-width: 1024px) {.CookiesNotification_cookieBanner__WV7AL {
        gap: 32px
}
    }

.DrawerNavigation_drawer__YeiFc {

    grid-template-columns: repeat(3, minmax(0, 1fr));

    display: grid;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 4px;
    background-color: #FFFFFF;
    border-top: 1px solid #CAD8E2;
    z-index: 500;
    box-shadow: 0px 19px 25px 0px #0000000F, 0px 2px 4px 0px #0000000A
}

    @media screen and (min-width: 768px) {.DrawerNavigation_drawer__YeiFc {
        display: none
}
    }

.DrawerNavigation_button__QMn26 {

    font-family: fields, arial, system-ui, serif;

    font-weight: 600;

    font-size: 14.222px;

    line-height: 150%;

    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    padding: 8px 16px
}

.Footer_footer__4vzqH {
    padding: 48px 16px;
    background-color: #303C69;
    border-radius: 24px 24px 0 0;
    color: #FFFFFF;
    fill: #FFFFFF;
}

    @media (min-width: 768px) {.Footer_footer__4vzqH {
        padding: 64px 32px
}
    }

    .Footer_footer__4vzqH a:focus {
            outline: 2px solid #007ACC;
            outline-offset: 4px;
        }

.Footer_grid__Yb5OK {
    max-width: 1152px;
    grid-template-columns: repeat(1, minmax(0, 1fr));

    display: grid;
    margin: 0 auto;
    gap: 48px;
}

@media (min-width: 768px) {

.Footer_grid__Yb5OK {
        grid-template-columns: repeat(3, minmax(0, 1fr));

        row-gap: 48px;
        -moz-column-gap: 32px;
             column-gap: 32px;
    }
    }

@media (min-width: 1024px) {

.Footer_grid__Yb5OK {
        grid-template-columns: 18.875rem 1fr 1fr 1fr;

        gap: 48px
}
    }

.Footer_details__e4cGM {
    display: flex;
    flex-direction: column;
    gap: 32px;
}

@media (min-width: 768px) {

.Footer_details__e4cGM {
        flex-direction: row;
        align-items: center;
        gap: 48px
}
    }

@media (min-width: 768px) and (max-width: 1024px) {

.Footer_details__e4cGM {
        grid-column: span 3 / span 3;

        justify-content: space-between;
    }
    }

@media (min-width: 1024px) {

.Footer_details__e4cGM {
        flex-direction: column;
        align-items: start;
        gap: 32px
}
    }

.FooterSlim_footer__n5K6F {
    padding: 48px 16px;
    background-color: #303C69;
    border-radius: 24px 24px 0 0;
    color: #FFFFFF;
    fill: #FFFFFF;
}

    @media (min-width: 768px) {.FooterSlim_footer__n5K6F {
        padding: 64px 32px
}
    }

    .FooterSlim_footer__n5K6F a:focus {
            outline: 2px solid #007ACC;
            outline-offset: 4px;
        }

.AccountMenuWrapper_AccountMenuWrapper___KTGg {
    width: 100%;
    max-width: 20rem;
    position: sticky;
    top: 0;
    flex-shrink: 0;
    flex-grow: 1;
    overflow: auto;
    max-height: 100vh;
    display: none;
    border-right: 1px solid #CAD8E2;
    background-color: #FFFFFF;
}

    @media (min-width: 960px) {.AccountMenuWrapper_AccountMenuWrapper___KTGg {
        display: block
}
    }

.Pagination_pagination__wxlOv {
    list-style: none;
    padding: 0;
    margin-bottom: 0;
    display: flex;
    justify-content: center;
}

.Pagination_item__d8HU6 {
    background: transparent;
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
    background-color: #FFFFFF;
    border: 1px solid #CAD8E2;
    border-radius: 0.6667rem;
    cursor: pointer;
    height: 2.5rem;
    width: 2.5rem;
    line-height: 1;
    margin: 0 4px;
    padding: 0;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}

.Pagination_item__d8HU6 > button {
    display: flex;
    align-items: center;
    justify-content: center;
}

@media screen and (min-width: 767px) {

.Pagination_item__d8HU6 {
        margin: 0 8px
}
    }

.Pagination_itemdisabled__t5m9o {
    cursor: not-allowed;
    opacity: 0.25;
}

.Pagination_itemcurrent__um0bt {
    box-shadow: 0 35px 45px -20px rgb(0 0 0 / 0.15);
    color: #FFFFFF;
    background: #303C69;
    border: 1px solid #303C69;
}

.PromotionCallout_borderColorDefault__oCUVx {
    --border-color: #CCF2FF;
}

.PromotionCallout_borderColorCard__FA14k {
    --border-color: #CAD8E2;
}

.PromotionCallout_wrapper__netJA {
    container-type: inline-size;

    border-radius: 24px;
    border: 1px solid var(--border-color, #CCF2FF);
    background: #FFFFFF;
}

.PromotionCallout_content__71sQz {
    display: flex;
    flex-direction: column;
    align-items: start;
    margin: 8px;
    border-radius: 24px;
    border: 1px solid var(--border-color, #ccf2ff80);
    overflow: hidden;
}

@container (min-width: 540px) {

.PromotionCallout_content__71sQz {
        margin: 16px
}
    }

@container (min-width: 720px) {

.PromotionCallout_content__71sQz {
        flex-direction: row;
        gap: 16px;
        align-items: stretch;
        justify-content: space-between;
        margin: 16px
}
    }

.PromotionCallout_content__71sQz > div:last-child,
    .PromotionCallout_content__71sQz > button:last-child {
        flex-grow: 0;
        flex-shrink: 0;
    }

.PromotionCallout_text__1sbJ8 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(8px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(8px * var(--tw-space-y-reverse));
}

.PromotionCallout_text__1sbJ8 {

    position: relative;
    padding: 32px 16px;
}

@container (min-width: 720px) {

.PromotionCallout_text__1sbJ8 {
        padding: 32px 24px
}
    }

.PromotionCallout_defaultButtonWrapper__vk4GB {
    position: relative;
    padding: 32px 16px;
    border-radius: 0 3.5rem 3.5rem 0;
    max-width: calc(100% - 1rem);
}

@container (min-width: 720px) {

.PromotionCallout_defaultButtonWrapper__vk4GB {
        display: flex;
        align-items: center;
        max-width: 18.75rem;
        padding: 0 24px 0 32px
}
    }

.PromotionCallout_buttonWrapper__IJcn5 {
    position: relative;
    padding: 32px;
    border-radius: 0 3.5rem 3.5rem 0;
    max-width: calc(100% - 1rem);
}

@container (min-width: 720px) {

.PromotionCallout_buttonWrapper__IJcn5 {
        display: flex;
        align-items: center;
        max-width: 18.75rem;
        padding: 0 24px 0 32px
}
    }

/* Circle */

.PromotionCallout_buttonWrapper__IJcn5:before {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        height: 100%;
        width: 50%;
        background: #CCF2FF;
        border-radius: 0 100% 100% 0;
    }

@supports (aspect-ratio: 1 / 1) {

.PromotionCallout_buttonWrapper__IJcn5:before {
            aspect-ratio: 1 / 1;
            width: auto
    }
        }

@container (min-width: 720px) {

.PromotionCallout_buttonWrapper__IJcn5:before {
            left: 0;
            right: auto;
            border-radius: 100% 0 0 100%
    }
        }

/* Square */

.PromotionCallout_buttonWrapper__IJcn5:after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: calc(100% - 6.875rem);
        height: 100%;
        background: #CCF2FF;
    }

@container (min-width: 720px) {

.PromotionCallout_buttonWrapper__IJcn5:after {
            left: auto;
            right: 0
    }
        }

.PromotionCallout_buttonWrapper__IJcn5 a {
        position: relative;
        z-index: 1;
    }

.AccountOpenOrdersListItem_card__BjG8B {

display: flex;

width: 100%;

flex-direction: column;

padding: 0;

}

.AccountOpenOrdersListItem_cardBody__e5SYC {

display: grid;

width: 100%;

grid-template-columns: repeat(2, minmax(0, 1fr));

row-gap: 24px;

padding: 16px;

}

@container (min-width: 768px) {

.AccountOpenOrdersListItem_cardBody__e5SYC {

grid-template-columns: repeat(3, minmax(0, 1fr));

row-gap: 24px;

}

    }

@container (min-width: 1152px) {

.AccountOpenOrdersListItem_cardBody__e5SYC {

grid-template-columns: repeat(3, minmax(0, 1fr));

-moz-column-gap: 48px;

     column-gap: 48px;

}

    }

.AccountOpenOrdersListItem_header__Dr5yF {

display: flex;

width: 100%;

flex-direction: row;

align-items: center;

justify-content: space-between;

padding: 16px;

}

@media (min-width: 768px) {

.AccountOpenOrdersListItem_header__Dr5yF {

padding-top: 24px;

padding-bottom: 24px;

}

}

.AccountOpenOrdersListItem_header__Dr5yF {

    border-bottom: 1px solid #CAD8E2;

}

.AccountOpenOrdersListItem_headerContent__aKXk2 {

display: flex;

width: 100%;

flex-direction: row;

justify-content: space-between;

}

@container (min-width: 768px) {

.AccountOpenOrdersListItem_headerContent__aKXk2 {

justify-content: flex-start;

-moz-column-gap: 16px;

     column-gap: 16px;

}

    }

.AccountOpenOrdersListItem_viewDetails__EjxNC {

white-space: nowrap;

    display: none;

}

@container (min-width: 768px) {

.AccountOpenOrdersListItem_viewDetails__EjxNC {

display: block;

}

    }

.AccountOpenOrdersListItem_viewDetails__EjxNC.AccountOpenOrdersListItem_footer__HmsIn {

display: block;

display: flex;

justify-content: flex-end;

padding-left: 16px;

padding-right: 16px;

padding-bottom: 16px;

}

@container (min-width: 768px) {

.AccountOpenOrdersListItem_viewDetails__EjxNC.AccountOpenOrdersListItem_footer__HmsIn {
            display: none
    }
        }

.AccountOpenOrdersListItem_issue__RkN0K {

display: flex;

width: 100%;

flex-direction: row;

align-items: center;

justify-content: space-between;

padding: 16px;

    border-bottom: 1px solid #CAD8E2;

}

.AccountOpenOrdersListItem_error__tDQkY {

display: flex;

flex-direction: row;

align-items: center;

justify-content: space-between;

-moz-column-gap: 8px;

     column-gap: 8px;

}

.AccountOpenOrdersListItem_orderPlaced__DKAEN {

order: 1;

}

.AccountOpenOrdersListItem_estimatedDelivery__8Xx_Y {

order: 2;

}

@container (min-width: 1152px) {

.AccountOpenOrdersListItem_estimatedDelivery__8Xx_Y {

order: 3;

grid-column: span 2 / span 2;

}

    }

.AccountOpenOrdersListItem_deliveringTo__bWAPv {

order: 3;

grid-column: span 2 / span 2;

}

@container (min-width: 768px) {

.AccountOpenOrdersListItem_deliveringTo__bWAPv {

grid-column: span 1 / span 1;

}

    }

@container (min-width: 1152px) {

.AccountOpenOrdersListItem_deliveringTo__bWAPv {

order: 2;

}

    }

.AccountOpenOrdersListItem_treatmentList__15Lou {

order: 4;

grid-column: span 2 / span 2;

display: flex;

flex-direction: column;

row-gap: 16px;

}

@container (min-width: 1152px) {

.AccountOpenOrdersListItem_treatmentList__15Lou {

order: 2;

grid-column: span 1 / span 1;

}

    }
.AccountPastOrdersTableEntry_fluid_table__row__F7Rp7 {
    overflow: hidden;

    border-bottom: 1px solid #CAD8E2;
}

    .AccountPastOrdersTableEntry_fluid_table__row__F7Rp7:last-child {
        border: 0;
    }

    @media screen and (min-width: 768px) {
        .AccountPastOrdersTableEntry_fluid_table__row__F7Rp7:last-child {
            border: 1px solid #CAD8E2;
        }
    }

    @media screen and (max-width: 767px) {.AccountPastOrdersTableEntry_fluid_table__row__F7Rp7 {
        display: flex;
        flex-flow: row wrap;
        width: 100%;
        position: relative;
        padding: 1.5rem 0
}

        .AccountPastOrdersTableEntry_fluid_table__row__F7Rp7 .AccountPastOrdersTableEntry_fluid_table__cell__SY5Ss {
            order: 1;
            margin-bottom: 12px;
        }

            .AccountPastOrdersTableEntry_fluid_table__row__F7Rp7 .AccountPastOrdersTableEntry_fluid_table__cell__SY5Ss:first-child {
                margin-bottom: 0;
            }

            .AccountPastOrdersTableEntry_fluid_table__row__F7Rp7 .AccountPastOrdersTableEntry_fluid_table__cell__SY5Ss.AccountPastOrdersTableEntry_mobile__0UpGX {
        display: flex;
        justify-content: space-between;
    }

                @media screen and (min-width: 768px) {

            .AccountPastOrdersTableEntry_fluid_table__row__F7Rp7 .AccountPastOrdersTableEntry_fluid_table__cell__SY5Ss.AccountPastOrdersTableEntry_mobile__0UpGX {
            flex-direction: column;
        }
                }

        .AccountPastOrdersTableEntry_fluid_table__row__F7Rp7 .AccountPastOrdersTableEntry_fluid_table__accent__oBB2a {
            flex-basis: 6rem;
            flex-grow: 1;
            text-align: right;
            order: 2;
        }
    }

    @media screen and (min-width: 768px)  {.AccountPastOrdersTableEntry_fluid_table__row__F7Rp7 {
        display: flex;
        width: 100%;
        position: relative
}

        .AccountPastOrdersTableEntry_fluid_table__row__F7Rp7 .AccountPastOrdersTableEntry_fluid_table__cell__SY5Ss {
            order: 99;
            padding: 12px;
            border-right: 1px solid #CAD8E2;
        }

                @media screen and (min-width: 768px) {

            .AccountPastOrdersTableEntry_fluid_table__row__F7Rp7 .AccountPastOrdersTableEntry_fluid_table__cell__SY5Ss.AccountPastOrdersTableEntry_not_mobile__GVLnd {
            display: flex;
            width: 10rem;
            flex-direction: column;
            justify-content: center;
            row-gap: 4px;
            padding-top: 16px;
            padding-bottom: 16px;
        }
                }

            .AccountPastOrdersTableEntry_fluid_table__row__F7Rp7 .AccountPastOrdersTableEntry_fluid_table__cell__SY5Ss:last-child {
                border-right: none;
            }

            @media and (min-width: 768px) {

        .AccountPastOrdersTableEntry_fluid_table__row__F7Rp7 .AccountPastOrdersTableEntry_fluid_table__cell__SY5Ss {
                padding: 16px
        }
            }

        .AccountPastOrdersTableEntry_fluid_table__row__F7Rp7 .AccountPastOrdersTableEntry_fluid_table__primary__PX6Kl {
        display: flex;
        flex: 1 1 0%;
        flex-direction: column;
        justify-content: center;
        padding-top: 16px;
        padding-bottom: 16px;
    }

        .AccountPastOrdersTableEntry_fluid_table__row__F7Rp7 .AccountPastOrdersTableEntry_fluid_table__accent__oBB2a {
            flex-basis: 6rem;
            flex-grow: 1;
            text-align: right;
            order: 2;
        }
    }

.AccountPastOrdersTableEntry_issue__bp1RT:before {
    content: '';
    position: absolute;
    left:0;
    top:0;
    height:100%;
    width: 0.25rem;
    background-color: #DB0F64;
}

.AccountPastOrdersTableEntry_fluid_table__actions__CeaGH {
    display: flex;
    width: 100%;
}

@media (min-width: 768px) {

    .AccountPastOrdersTableEntry_fluid_table__actions__CeaGH {
        width: auto;
        flex-direction: column;
        justify-content: center;
    }
}

.AccountPastOrdersTableEntry_order_listing__row__QRE9n {
    display: flex;
    flex-direction: column;
    padding: 12px;
}

@media screen and (min-width: 768px) {

.AccountPastOrdersTableEntry_order_listing__row__QRE9n {
        border: 1px solid #CAD8E2;
        border-top: none;
        flex-direction: row;
        padding: unset;
        border-left: 1px solid #CAD8E2
}

        .AccountPastOrdersTableEntry_order_listing__row__QRE9n:last-child {
            border-top: none;
            border-bottom-left-radius: 12px;
            border-bottom-right-radius: 12px;
        }
    }

.AccountPastOrdersTableEntry_button__sgeK5 {
    align-self: end;
}

@media screen and (min-width: 768px) {

.AccountPastOrdersTableEntry_tag__Jgyb0 {
        margin-top: 0;
    }
    }

.AccountPastOrdersTableEntry_accountTestsRow__RFceQ {
    display: grid;
    align-items: center;
}

@media (min-width: 768px) {

    .AccountPastOrdersTableEntry_accountTestsRow__RFceQ {
        display: flex;
        align-items: stretch;
    }
}
.AccountPastOrdersTable_fluid_table__DtaLt {
    width: 100%;
    border-collapse: collapse;
    background-color: white;
    display: flex;
    flex-direction: column;
    border-radius: 12px;
    border: 1px solid #CAD8E2;
}

    @media screen and (min-width: 768px) {.AccountPastOrdersTable_fluid_table__DtaLt {
        display: table-row-group;
        border-style: none;
    }
    }

.AccountPastOrdersTable_fluid_table__header__We5k2 {
    display: none;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    background-color: #303C69;
}

.AccountPastOrdersTable_fluid_table__header__We5k2 th {
        padding: 16px;
        text-align: left;
    }

@media screen and (min-width: 768px) {

.AccountPastOrdersTable_fluid_table__header__We5k2 {
        display: flex
}

        .AccountPastOrdersTable_fluid_table__header__We5k2 + .AccountPastOrdersTable_fluid_table__body__epc_m {
            display: flex;
            flex-direction: column;
        }
    }

.AccountPastOrdersTable_fluid_table__row__LbqHe {
    display: flex;
    width: 100%;
}
.PrescriptionRequestHero_prescriptionHero__n1LGW {
    display: flex;
    flex-direction: column;
    gap: 24px;
    justify-content: space-between;
}

    @media (min-width: 1024px) {.PrescriptionRequestHero_prescriptionHero__n1LGW {
        flex-direction: row;
        align-items: center
}
    }

.PrescriptionRequestHero_container__fFVM8 {
    padding: 0 16px;
    margin-left: auto;
    margin-right: auto;
}

@media (min-width: 768px) {

.PrescriptionRequestHero_container__fFVM8 {
        padding: 0 24px
}
    }

.PrescriptionRequestHero_spacing__6l5WJ {
    margin-top: 48px;
}

@media (min-width: 1024px) {

.PrescriptionRequestHero_spacing__6l5WJ {
        margin-top: 64px
}
    }

.PrescriptionRequestHero_spacing__6l5WJ:last-child {
        margin-bottom: 48px;
    }

@media (min-width: 1024px) {

.PrescriptionRequestHero_spacing__6l5WJ:last-child {
            margin-bottom: 64px
    }
        }

.PrescriptionRequestHero_howItWorks__ErKok > div {
        padding-bottom: 15.25rem;
    }

@media (min-width: 768px) {

.PrescriptionRequestHero_howItWorks__ErKok > div {
            padding-bottom: 128px
    }
        }

@media (min-width: 1024px) {

.PrescriptionRequestHero_howItWorks__ErKok > div {
            padding-bottom: 80px
    }
        }

.PrescriptionRequestHero_howItWorks__ErKok + div {
        margin-top: -13.75rem;
    }

@media (min-width: 768px) {

.PrescriptionRequestHero_howItWorks__ErKok + div {
        margin-top: -112px;
    }
        }

@media (min-width: 1024px) {

.PrescriptionRequestHero_howItWorks__ErKok + div {
        margin-top: -80px;
    }
        }


.Regulation_content__2_bMy {

    max-width: 1152px;

    padding-left: 16px;

    padding-right: 16px;

    margin-left: auto;

    margin-right: auto
}

@media (min-width: 768px) {

    .Regulation_content__2_bMy {

        padding-left: 24px;

        padding-right: 24px
    }
}

.Regulation_calloutSection__kbMie {

    max-width: 1152px;

    padding-left: 16px;

    padding-right: 16px;

    margin-left: auto;

    margin-right: auto;

    margin-top: 48px;

    margin-bottom: 48px
}

@media (min-width: 768px) {

    .Regulation_calloutSection__kbMie {

        padding-left: 24px;

        padding-right: 24px;

        margin-top: 64px;

        margin-bottom: 64px
    }
}
.page_container__oETVD {
    max-width: 1152px;

    margin-left: auto;
    margin-right: auto;
    padding: 0 16px;
}

    @media (min-width: 768px) {.page_container__oETVD {
        padding: 0 24px
}
    }

.page_spacing__uu9Hm {
    margin-top: 48px;
}

@media (min-width: 1024px) {

.page_spacing__uu9Hm {
        margin-top: 64px
}
    }

.page_spacing__uu9Hm:last-child {
        margin-bottom: 48px;
    }

@media (min-width: 1024px) {

.page_spacing__uu9Hm:last-child {
            margin-bottom: 64px
    }
        }

.page_grid__IrrEp {
    grid-template-columns: repeat(1, minmax(0, 1fr));

    display: grid;
    gap: 16px;
}

@media (min-width: 1024px) {

.page_featuredGridOfTwoOrLess__tyLxB {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }
    }

@media (min-width: 768px) {

.page_featuredGridOfThreeOrMore__i17HU {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }
    }

@media (min-width: 1024px) {

.page_featuredGridOfThreeOrMore__i17HU {
        grid-template-columns: repeat(6, minmax(0, 1fr));
    }
    }

@media (min-width: 768px) {
            .page_featuredGridOfThreeOrMore__i17HU li:nth-child(1),
            .page_featuredGridOfThreeOrMore__i17HU li:nth-child(2) {
        grid-column: span 2 / span 2;
    }
        }

@media (min-width: 1024px) {

.page_featuredGridOfThreeOrMore__i17HU li {
        grid-column: span 2 / span 2;
    }

            .page_featuredGridOfThreeOrMore__i17HU li:first-child {
        grid-column: span 3 / span 3;
        grid-row: span 2 / span 2;
    }

            .page_featuredGridOfThreeOrMore__i17HU li:nth-child(2),
            .page_featuredGridOfThreeOrMore__i17HU li:nth-child(3) {
        grid-column: span 3 / span 3;
    }
        }

