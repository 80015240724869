.container {

    @apply  flex
        flex-col
        gap-y-100;

    
    @container (min-width: 768px) {

        @apply  flex-row
            justify-between
            items-center;
    }
}