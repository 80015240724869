/* Generic page container styling. */
.container {
    margin-left: auto;
    margin-right: auto;
    padding: 0 theme('container.gutters.small');

    @media (min-width: theme('screens.md')) {
        padding: 0 theme('container.gutters.medium');
    }
}

/* Generic vertical spacing for sections. */
.section {
    margin-top: theme('spacing.rhythm-medium');

    @media (min-width: theme('screens.lg')) {
        margin-top: theme('spacing.rhythm-large');
    }

    &:last-child {
        margin-bottom: theme('spacing.rhythm-medium');

        @media (min-width: theme('screens.lg')) {
            margin-bottom: theme('spacing.rhythm-large');
        }
    }
}

.affiliateBanner {
    & + div {
        margin-top: theme('spacing.rhythm-small');
    }
}

.description {
    @apply grid-cols-1;

    display: grid;
    gap: theme('spacing.rhythm-medium');
    max-width: theme('container.sizes.large');

    @media (min-width: theme('screens.lg')) {
        gap: theme('spacing.rhythm-large');

        &:has(> div:not(:only-of-type)) {
            @apply grid-cols-2;
        }
    }
}

.summary {
    max-width: theme('container.sizes.large');
    margin-top: theme('spacing.100');

    @media (min-width: theme('screens.md')) {
        margin-top: theme('spacing.rhythm-medium');
    }

    @media (min-width: theme('screens.lg')) {
        margin-top: theme('spacing.rhythm-large');
    }
}
