.container {
    @apply container-large container-gutters-small md:container-gutters-medium mx-auto space-y-150;
}

.upperButton {
    @apply hidden;

    @container (min-width: 1152px) {
        @apply block;
    }
}
