.bookmark {
    border: theme('bookmarkNavigation.border');
    background: theme('bookmarkNavigation.background');
    border-radius: theme('bookmarkNavigation.borderRadius');
    box-shadow: theme('bookmarkNavigation.shadow');
    padding: theme('bookmarkNavigation.padding');
}

.bookmarkNavItem{

    @apply flex justify-between items-center gap-100 text-090;
    padding: theme('bookmarkNavigationItem.padding');

    &:hover {
        border-radius: theme('bookmarkNavigationItem.borderRadius');
        background: theme('bookmarkNavigationItem.hover.background');
        color: theme('bookmarkNavigationItem.hover.textColor');
        fill: theme('bookmarkNavigationItem.hover.iconColor');

    }
}