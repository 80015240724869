.tabs {

    @apply type-heading type-100;

    gap: theme('spacing.100');
    color: theme('colors.content.quiet');
}

.tab {
    position: relative;
    display: block;
    padding: theme('spacing.075') theme('spacing.150');
    margin-bottom: -1px;
    border: 1px solid transparent;
    border-radius: theme('borderRadius.100') theme('borderRadius.100') 0 0;
    background: transparent;
}

.tabActive {
    border: theme('card.bordered.border');
    border-bottom-color: transparent;
    background: theme('tab.active.background');
    color: theme('colors.content.foundation');
}
