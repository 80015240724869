.directors {

    @apply max-w-[36.25rem] lg:container-small mx-auto;
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    gap: theme('spacing.100');
}


.quote {
    div {
        @apply justify-start;
    }
}