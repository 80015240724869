.calloutSection {

    @apply
        overflow-hidden
        p-050
        container-gutters-small
        md:p-100
        md:container-gutters-medium;

    border: theme("border.light");
    border-radius: theme("borderRadius.200");
    border-color: theme("colors.decorators.accent");
}

.calloutContent {
    border: theme("border.light");
    padding: theme("spacing.200");
    border-radius: theme("borderRadius.200");
    border-color: theme("colors.decorators.accent");

    @apply flex
        flex-col
        gap-200
        mx-auto
        items-center
        py-300
        max-w-6xl
        lg:justify-between
        lg:flex-row
        lg:gap-100;

    @media (min-width: 619px) {

        @apply py-400;
    }

    @screen lg {

        @apply space-y-0;
    }
}
