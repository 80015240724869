.authorMeta {
    @apply
        space-y-200
        py-200
        bg-layers-canvas
        md:py-300;
}

.container {
    max-width: theme('container.sizes.large');
    padding: 0 theme('container.gutters.small');
    margin-left: auto;
    margin-right: auto;

    @media (min-width: theme('screens.md')) {
        padding: 0 theme('container.gutters.medium');
    }

    @media (min-width: 1210px) {
        padding: 0;
    }
}

.metadata {
    @apply
        flex
        justify-between
        pb-100;

    border-bottom: theme('border.light');

    &:last-of-type {
        border-bottom: none;
    }
}

.authorInfo {
    @apply
        space-y-075
        pb-200
        md:pb-0;
}

.linksContainer {
    @apply
        col-span-12
        pb-100
        md:col-span-4;

    border-bottom: theme('border.light');

    @screen md {
        @apply pb-0;

        border-bottom: none;
    }

    &:last-of-type {
        border-bottom: none;
    }
}
