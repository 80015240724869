.link {
    display: flex;
    align-items: center;
    gap: theme('siteHeaderLink.spacing');
    padding: theme('siteHeaderLink.padding');
    border-radius: theme('borderRadius.050');
    background-color: rgb(00 00 00 / 0);
    transition: theme('transitionDuration.default') background-color theme('transitionTimingFunction.default');

    @media screen and (min-width: 899px) {
        &:hover,
        &:focus-within {
            background-color: theme('siteHeaderLink.hover.background');
        }

        &:hover {
            outline: none;
        }

        &:focus-within {
            outline: 2px solid theme('colors.layers.strong');
        }
    }
}
