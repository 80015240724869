.fluid_table__row {
    overflow: hidden;

    border-bottom: theme('border.light');

    &:last-child {
        border: 0;
    }
    
    @media screen and (min-width: theme('screens.md')) {
        &:last-child {
            border: theme('border.light');
        }
    }
    
    
    @media screen and (max-width: 767px) {
        display: flex;
        flex-flow: row wrap;
        width: 100%;
        position: relative;
        padding: 1.5rem 0;

        .fluid_table__cell {
            order: 1;
            margin-bottom: theme('spacing.075');

            &:first-child {
                margin-bottom: 0;
            }

            &.mobile {

                @apply  flex justify-between;

                @media screen and (min-width: theme('screens.md')) {

                    @apply  flex-col;
                }
            }
        }

        .fluid_table__accent {
            flex-basis: 6rem;
            flex-grow: 1;
            text-align: right;
            order: 2;
        }
    }

    @media screen and (min-width: theme('screens.md'))  {
        display: flex;
        width: 100%;
        position: relative;

        .fluid_table__cell {
            order: 99;
            padding: theme('table.cell.padding.default');
            border-right: 1px solid theme('colors.decorators.subtle');

            &.not_mobile {

                @media screen and (min-width: theme('screens.md')) {

                    @apply
                        flex
                        flex-col
                        justify-center
                        w-[10rem]
                        py-[theme('table.cell.padding.large')]
                        gap-y-025;
                }
            }

            &:last-child {
                border-right: none;
            }

            @media and (min-width: theme('screens.md')) {
                padding: theme("table.cell.padding.large");
            }
        }

        .fluid_table__primary {

            @apply
                flex
                flex-1
                flex-col
                justify-center
                py-[theme('table.cell.padding.large')];

        }

        .fluid_table__accent {
            flex-basis: 6rem;
            flex-grow: 1;
            text-align: right;
            order: 2;
        }
    }
}

.issue:before {
    content: '';
    position: absolute;
    left:0;
    top:0;
    height:100%;
    width: 0.25rem;
    background-color: theme('colors.status.error.default');
}

.fluid_table__actions {

    @apply flex w-full md:w-auto md:flex-col md:justify-center;
}

.order_listing__row {
    display: flex;
    flex-direction: column;
    padding: theme('table.cell.padding.default');
    
    @media screen and (min-width: theme('screens.md')) {
        border: 1px solid theme('colors.decorators.subtle');
        border-top: none;
        flex-direction: row;
        padding: unset;
        border-left: 1px solid theme('colors.decorators.subtle');

        &:last-child {
            border-top: none;
            border-bottom-left-radius: theme('card.borderRadius.default');
            border-bottom-right-radius: theme('card.borderRadius.default');
        }
    }
}

.button {
    align-self: end;
}

.tag {
    @media screen and (min-width: theme('screens.md')) {

        @apply  mt-0;
    }
}

.accountTestsRow {
    @apply grid md:flex items-center md:items-stretch;
}