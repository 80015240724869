.tableMinimal {
    margin-top: theme('spacing.100');
    border: 1px solid theme('colors.decorators.subtle');
    border-radius: theme('borderRadius.100');
    text-align: left;
    overflow-x: auto;

    table {
        min-width: 100%;
        width: auto;
        border-collapse: collapse;
        border-spacing: 0;

        tr + tr {
            border-top: 1px solid theme('colors.decorators.subtle');
        }

        td {
            width: 50%;
            padding: theme('spacing.100');

            & + td {
                border-left: 1px solid theme('colors.decorators.subtle');
            }
        }
    }
}
