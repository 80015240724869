.serviceStamp {
    @apply
    container-medium
    container-gutters-small
    md:container-gutters-medium
    mx-auto
    mt-rhythm-medium
    lg:mt-rhythm-large;
}

.trustBlock {
    @apply
    container-large
    container-gutters-small
    md:container-gutters-medium
    mt-rhythm-medium
    lg:mt-rhythm-large
    mx-auto;
}

.container {
    @apply
    container-medium
    container-gutters-small
    md:container-gutters-medium
    mx-auto
    my-rhythm-medium
    lg:my-rhythm-large;
}

.ordering {
    @apply
    container-xlarge
    mx-auto
    container-gutters-small
    md:container-gutters-medium
    mt-rhythm-medium
    lg:mt-rhythm-large;
}