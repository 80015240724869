.flash {

    @apply flex
        gap-050
        items-center
        justify-between;

    padding: theme('banner.sizes.medium');
    border-radius: theme('borderRadius.100');
    border: 1px dashed theme('colors.layers.highlight');
}

.flashIcon {
    fill: theme('banner.general.iconColor');
}
