.prescriptionHero {
    display: flex;
    flex-direction: column;
    gap: theme('spacing.150');
    justify-content: space-between;

    @media (min-width: theme('screens.lg')) {
        flex-direction: row;
        align-items: center;
    }
}

.container {
    padding: 0 theme('container.gutters.small');
    margin-left: auto;
    margin-right: auto;

    @media (min-width: theme('screens.md')) {
        padding: 0 theme('container.gutters.medium');
    }
}

.spacing {
    margin-top: theme('spacing.rhythm-medium');

    @media (min-width: theme('screens.lg')) {
        margin-top: theme('spacing.rhythm-large');
    }

    &:last-child {
        margin-bottom: theme('spacing.rhythm-medium');

        @media (min-width: theme('screens.lg')) {
            margin-bottom: theme('spacing.rhythm-large');
        }
    }
}

.howItWorks {
    & > div {
        padding-bottom: 15.25rem;

        @media (min-width: theme('screens.md')) {
            padding-bottom: theme('spacing.800');
        }

        @media (min-width: theme('screens.lg')) {
            padding-bottom: theme('spacing.500');
        }
    }

    & + div {
        margin-top: -13.75rem;

        @media (min-width: theme('screens.md')) {
            @apply -mt-700;
        }

        @media (min-width: theme('screens.lg')) {
            @apply -mt-500;
        }
    }
}
