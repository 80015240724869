.AccountMenuWrapper {
    width: 100%;
    max-width: 20rem;
    position: sticky;
    top: 0;
    flex-shrink: 0;
    flex-grow: 1;
    overflow: auto;
    max-height: 100vh;
    display: none;
    border-right: 1px solid theme('colors.decorators.subtle');
    background-color: theme('backgroundColor.layers.foreground');

    @media (min-width: 960px) {
        display: block;
    }
}
