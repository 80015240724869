.container {
    position: absolute;
    left: 0;
    right: 0;
    display: flex;
    max-width: theme('container.sizes.large');
    margin-left: auto;
    margin-right: auto;
}

.containerPadding {
    padding: 0 theme('container.gutters.small');

    @media (min-width: theme('screens.md')) {
        padding: theme('spacing.025') theme('container.gutters.medium');
    }
}

.list {
    display: flex;
    align-items: center;
    gap: theme('breadcrumb.spacing');
    overflow-x: auto;
}

.listItem {
    display: flex;
    align-items: center;
    gap: theme('breadcrumb.spacing');
    flex-shrink: 0;
}
